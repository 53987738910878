import React, { useEffect, useRef, useState } from "react";
import imageCompression from "browser-image-compression";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";

import {
  addBalance,
  addNewWord,
  getAllUsers,
  getAllWords,
  getBalanceLogs,
  getUser,
  getUserBalance,
  setTicketClosed,
  updateWord,
} from "../../ourapis/api";
import { useStateContext } from "../../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";

import { AiFillCaretDown } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotCard from "../../components/NotCard";
import Swal from "sweetalert2";
import TicketCard from "../../components/UserCard";
import { MdOutlineCancel } from "react-icons/md";
import axios from "axios";

registerLocale("tr", tr);

const tableData = [
  {
    title: "ID",
  },
  {
    title: "Türkçe",
  },

  {
    title: "Kazakça",
  },
  {
    title: "Rusça",
  },
  {
    title: "Tarih",
  },
];

const dummyData = [
  {
    wordTurkce: "elma",
    wordKazakca: "алма",
    wordRusca: "яблоко",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Elma, genellikle kırmızı, yeşil veya sarı renklerde olan bir meyvedir.",
    category: "meyve",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kedi",
    wordKazakca: "мысық",
    wordRusca: "кошка",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kedi, evcil hayvan olarak sıkça beslenen küçük memeli bir hayvandır.",
    category: "hayvan",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kitap",
    wordKazakca: "кітап",
    wordRusca: "книга",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kitap, yazılı veya basılı sayfalar halinde ciltlenmiş bilgi veya hikaye içeren bir yayındır.",
    category: "nesne",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "araba",
    wordKazakca: "көлік",
    wordRusca: "машина",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Araba, insanların bir yerden bir yere gitmesini sağlayan motorlu bir kara taşıtıdır.",
    category: "taşıt",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "çiçek",
    wordKazakca: "гүл",
    wordRusca: "цветок",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Çiçek, bitkilerin üreme organlarını içeren ve genellikle renkli olan kısmıdır.",
    category: "bitki",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "elma",
    wordKazakca: "алма",
    wordRusca: "яблоко",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Elma, genellikle kırmızı, yeşil veya sarı renklerde olan bir meyvedir.",
    category: "meyve",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kedi",
    wordKazakca: "мысық",
    wordRusca: "кошка",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kedi, evcil hayvan olarak sıkça beslenen küçük memeli bir hayvandır.",
    category: "hayvan",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kitap",
    wordKazakca: "кітап",
    wordRusca: "книга",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kitap, yazılı veya basılı sayfalar halinde ciltlenmiş bilgi veya hikaye içeren bir yayındır.",
    category: "nesne",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "araba",
    wordKazakca: "көлік",
    wordRusca: "машина",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Araba, insanların bir yerden bir yere gitmesini sağlayan motorlu bir kara taşıtıdır.",
    category: "taşıt",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "çiçek",
    wordKazakca: "гүл",
    wordRusca: "цветок",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Çiçek, bitkilerin üreme organlarını içeren ve genellikle renkli olan kısmıdır.",
    category: "bitki",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "elma",
    wordKazakca: "алма",
    wordRusca: "яблоко",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Elma, genellikle kırmızı, yeşil veya sarı renklerde olan bir meyvedir.",
    category: "meyve",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kedi",
    wordKazakca: "мысық",
    wordRusca: "кошка",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kedi, evcil hayvan olarak sıkça beslenen küçük memeli bir hayvandır.",
    category: "hayvan",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kitap",
    wordKazakca: "кітап",
    wordRusca: "книга",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kitap, yazılı veya basılı sayfalar halinde ciltlenmiş bilgi veya hikaye içeren bir yayındır.",
    category: "nesne",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "araba",
    wordKazakca: "көлік",
    wordRusca: "машина",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Araba, insanların bir yerden bir yere gitmesini sağlayan motorlu bir kara taşıtıdır.",
    category: "taşıt",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "çiçek",
    wordKazakca: "гүл",
    wordRusca: "цветок",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Çiçek, bitkilerin üreme organlarını içeren ve genellikle renkli olan kısmıdır.",
    category: "bitki",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "elma",
    wordKazakca: "алма",
    wordRusca: "яблоко",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Elma, genellikle kırmızı, yeşil veya sarı renklerde olan bir meyvedir.",
    category: "meyve",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kedi",
    wordKazakca: "мысық",
    wordRusca: "кошка",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kedi, evcil hayvan olarak sıkça beslenen küçük memeli bir hayvandır.",
    category: "hayvan",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kitap",
    wordKazakca: "кітап",
    wordRusca: "книга",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kitap, yazılı veya basılı sayfalar halinde ciltlenmiş bilgi veya hikaye içeren bir yayındır.",
    category: "nesne",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "araba",
    wordKazakca: "көлік",
    wordRusca: "машина",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Araba, insanların bir yerden bir yere gitmesini sağlayan motorlu bir kara taşıtıdır.",
    category: "taşıt",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "çiçek",
    wordKazakca: "гүл",
    wordRusca: "цветок",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Çiçek, bitkilerin üreme organlarını içeren ve genellikle renkli olan kısmıdır.",
    category: "bitki",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "elma",
    wordKazakca: "алма",
    wordRusca: "яблоко",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Elma, genellikle kırmızı, yeşil veya sarı renklerde olan bir meyvedir.",
    category: "meyve",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kedi",
    wordKazakca: "мысық",
    wordRusca: "кошка",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kedi, evcil hayvan olarak sıkça beslenen küçük memeli bir hayvandır.",
    category: "hayvan",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kitap",
    wordKazakca: "кітап",
    wordRusca: "книга",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kitap, yazılı veya basılı sayfalar halinde ciltlenmiş bilgi veya hikaye içeren bir yayındır.",
    category: "nesne",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "araba",
    wordKazakca: "көлік",
    wordRusca: "машина",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Araba, insanların bir yerden bir yere gitmesini sağlayan motorlu bir kara taşıtıdır.",
    category: "taşıt",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "çiçek",
    wordKazakca: "гүл",
    wordRusca: "цветок",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Çiçek, bitkilerin üreme organlarını içeren ve genellikle renkli olan kısmıdır.",
    category: "bitki",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "elma",
    wordKazakca: "алма",
    wordRusca: "яблоко",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Elma, genellikle kırmızı, yeşil veya sarı renklerde olan bir meyvedir.",
    category: "meyve",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kedi",
    wordKazakca: "мысық",
    wordRusca: "кошка",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kedi, evcil hayvan olarak sıkça beslenen küçük memeli bir hayvandır.",
    category: "hayvan",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kitap",
    wordKazakca: "кітап",
    wordRusca: "книга",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kitap, yazılı veya basılı sayfalar halinde ciltlenmiş bilgi veya hikaye içeren bir yayındır.",
    category: "nesne",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "araba",
    wordKazakca: "көлік",
    wordRusca: "машина",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Araba, insanların bir yerden bir yere gitmesini sağlayan motorlu bir kara taşıtıdır.",
    category: "taşıt",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "çiçek",
    wordKazakca: "гүл",
    wordRusca: "цветок",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Çiçek, bitkilerin üreme organlarını içeren ve genellikle renkli olan kısmıdır.",
    category: "bitki",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "elma",
    wordKazakca: "алма",
    wordRusca: "яблоко",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Elma, genellikle kırmızı, yeşil veya sarı renklerde olan bir meyvedir.",
    category: "meyve",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kedi",
    wordKazakca: "мысық",
    wordRusca: "кошка",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kedi, evcil hayvan olarak sıkça beslenen küçük memeli bir hayvandır.",
    category: "hayvan",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "kitap",
    wordKazakca: "кітап",
    wordRusca: "книга",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Kitap, yazılı veya basılı sayfalar halinde ciltlenmiş bilgi veya hikaye içeren bir yayındır.",
    category: "nesne",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "araba",
    wordKazakca: "көлік",
    wordRusca: "машина",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Araba, insanların bir yerden bir yere gitmesini sağlayan motorlu bir kara taşıtıdır.",
    category: "taşıt",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
  {
    wordTurkce: "çiçek",
    wordKazakca: "гүл",
    wordRusca: "цветок",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Red_Apple.jpg",
    description:
      "Çiçek, bitkilerin üreme organlarını içeren ve genellikle renkli olan kısmıdır.",
    category: "bitki",
    delete: false,
    createdAt: "2024-07-27T08:01:45.321+00:00",
  },
];

const HomePage = () => {
  const { jwtToken, myEmail, setScreenSize, screenSize } = useStateContext();

  const [showUserModal, setShowUserModal] = useState(false);
  const [clickedTicketID, setClickedTicketID] = useState();
  const [clickedWord, setClickedWord] = useState({
    wordTurkce: "",
    wordKazakca: "",
    wordRusca: "",
    audioKazakcaURL: "",
    audioTurkceURL: "",
    audioRuscaURL: "",
    imageURL: "",
    description: "",
    category: "",
    deleted: false,
    createdAt: Date.now(),
  });

  const [searchText, setSearchText] = useState(""); // Arama metni için bir durum (state)
  const [searchResult, setSearchResult] = useState();
  const [searched, setSearched] = useState(false);
  const [users, setUsers] = useState([]);
  const [words, setWords] = useState([]);
  const [newWord, setNewWord] = useState(false);

  const [sorted, setSorted] = useState(false);
  const [sortedName, setSortedName] = useState("Tarih");
  const [sortedData, setSortedData] = useState([]);

  const [audio, setAudio] = useState(null); // Kazakça
  const [response, setResponse] = useState(""); // Kazakça
  const [audioTurkce, setAudioTurkce] = useState(null);
  const [audioRusca, setAudioRusca] = useState(null);
  const [responseTurkce, setResponseTurkce] = useState("");
  const [responseRusca, setResponseRusca] = useState("");

  const [selectAll, setSelectAll] = useState(false);

  // KAZAKÇA
  const handleAudioChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handleAudioChangeTurkce = (e) => {
    setAudioTurkce(e.target.files[0]);
  };

  const handleAudioChangeRusca = (e) => {
    setAudioRusca(e.target.files[0]);
  };

  // KAZAKÇA
  const handleAudioUpload = () => {
    const formData = new FormData();
    formData.append("audio", audio);
    setUploading(true);

    axios
      .post("https://storage.leksilink.com/upload_audio.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setResponse(response.data.message);
        //console.log("Uploaded file path:", response.data.file_path);
        setClickedWord((prev) => ({
          ...prev,
          audioKazakcaURL:
            "https://storage.leksilink.com/" + response.data.file_path,
        }));
        setUploading(false);
      })
      .catch((error) => {
        //console.error("Error uploading audio:", error);
        setUploading(false);
      });
  };

  const handleAudioUploadTurkce = () => {
    const formData = new FormData();
    formData.append("audio", audioTurkce);
    setUploading(true);

    axios
      .post("https://storage.leksilink.com/upload_audio.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setResponseTurkce(response.data.message);
        //console.log("Uploaded file path (Turkce):", response.data.file_path);
        setClickedWord((prev) => ({
          ...prev,
          audioTurkceURL:
            "https://storage.leksilink.com/" + response.data.file_path,
        }));
        setUploading(false);
      })
      .catch((error) => {
        //console.error("Error uploading audio (Turkce):", error);
        setUploading(false);
      });
  };

  const handleAudioUploadRusca = () => {
    const formData = new FormData();
    formData.append("audio", audioRusca);
    setUploading(true);

    axios
      .post("https://storage.leksilink.com/upload_audio.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setResponseRusca(response.data.message);
        //console.log("Uploaded file path (Rusca):", response.data.file_path);
        setClickedWord((prev) => ({
          ...prev,
          audioRuscaURL:
            "https://storage.leksilink.com/" + response.data.file_path,
        }));
        setUploading(false);
      })
      .catch((error) => {
        console.error("Error uploading audio (Rusca):", error);
        setUploading(false);
      });
  };

  useEffect(() => {
    if (jwtToken) {
      getAllWords(myEmail, jwtToken, setWords);
    }
  }, [jwtToken]);

  // Arama kutusuna girilen metni güncelleyen işlev
  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);

    if (e.target.value === "") {
      setSearched(false);
    }
  };

  // Arama işlemini gerçekleştiren işlev
  const performSearch = () => {
    setSearched(true);
    const lowerSearchText = searchText.toLowerCase();

    // Arama metni ile eşleşen müşterileri filtrele
    const filteredWords = words.filter((item) => {
      return item.wordTurkce.toLowerCase().includes(lowerSearchText);
    });

    // Filtrelenmiş müşterileri göster
    // Daha sonra bu müşterileri map ile döngüye alabilirsiniz.
    setSearchResult(filteredWords);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      performSearch();
    }
  };

  // DATA - Sıralama işlemleri
  const onTitleClickHandler = (title) => {
    if (title === "Tarih") {
      // Tarihe göre sıralama işlemi
      let sortedDatas = users;
      if (sorted) {
        sortedDatas.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateA - dateB;
        });
      } else {
        sortedDatas.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        });
      }

      setSortedData(sortedDatas);
      setSorted(!sorted);
      setSortedName("Tarih");
    }
  };

  // MODAL IÇIN GEREKLI BÖLÜMLER
  // MODAL IÇIN GEREKLI BÖLÜMLER
  // MODAL IÇIN GEREKLI BÖLÜMLER
  // MODAL IÇIN GEREKLI BÖLÜMLER

  // diğer sayfalardan gelen mail bilgisini tutuyoruz

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [walletID, setWalletID] = useState("");
  const [balance, setBalance] = useState("");

  const [startDate, setStartDate] = useState(Date.now());

  const [addedBalance, setAddedBalance] = useState(""); // Arama metni için bir durum (state)
  const [balanceLogs, setBalanceLogs] = useState(""); // Arama metni için bir durum (state)

  // Güncelleme işlemleri sonrası gösterilecek alert
  const notifySuccess = (text) =>
    toast.success(text, {
      position: toast.POSITION.TOP_CENTER,
    });

  const handleNoteTextChange = (e) => {
    setAddedBalance(e.target.value);
  };

  const handleKeyPressNote = (e) => {
    if (e.key === "Enter") {
      //console.log("Enter tuşlandı");
    }
  };

  // Gönder butonuna basıldığında çalışacak fonksiyon
  const handleButton = () => {
    Promise.all([
      addedBalance !== ""
        ? addBalance(email, walletID, myEmail, addedBalance, jwtToken)
        : null,
    ]).then((values) => {
      getBalanceLogs(myEmail, email, jwtToken, setBalanceLogs);
      setAddedBalance("");
      // console.log(typeof balance);
      // console.log(typeof addedBalance);

      setBalance(balance + parseFloat(addedBalance));
      notifySuccess("Cevabınız eklendi.");
    });
  };

  // Diğer sayfalardan gelindiğinde kullanıcı verilerini getiren fonkiyon
  // Bilgiler güncellendiğinde tekrardan çağrılıyor
  const getUserInfo = (email) => {
    if (jwtToken && email) {
      getUser(email, jwtToken, setName, setEmail, setBalance);

      getBalanceLogs(myEmail, email, jwtToken, setBalanceLogs);
    }
  };

  // MODALIN YÜKSEKLİĞİ İÇİN BÖLÜM

  const [modalHeight, setModalHeight] = useState(0);
  useEffect(() => {
    const updateModalHeight = () => {
      setModalHeight(window.innerHeight + 200);
    };

    // Sayfa yüklendiğinde ve pencere boyutu değiştiğinde modal yüksekliğini güncelle
    window.addEventListener("resize", updateModalHeight);
    updateModalHeight(); // Sayfa yüklendiğinde modal yüksekliği için başlangıç değerini ayarla

    // Component unmount olduğunda event listener'ı temizle
    return () => {
      window.removeEventListener("resize", updateModalHeight);
    };
  }, []); // Boş dependency array, sadece bir kere çalışmasını sağlar

  // ESC ye basıldığında modali kapatan bölüm
  useEffect(() => {
    const handleKeyPress = (event) => {
      // keyCode 27 corresponds to the "esc" key
      if (event.keyCode === 27) {
        setShowUserModal(false);
        setMessage("");
        setResponse("");
        setNewWord(false);
        setClickedWord({
          wordTurkce: "",
          wordKazakca: "",
          wordRusca: "",
          audioKazakcaURL: "",
          audioTurkceURL: "",
          audioRuscaURL: "",
          imageURL: "",
          description: "",
          category: "",
          deleted: false,
          createdAt: Date.now(),
        });
      }
    };

    // Event listener eklemek
    window.addEventListener("keydown", handleKeyPress);

    // Component unmount olduğunda event listener'ı kaldırmak
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [setShowUserModal]);

  // Ekran boyutu ile mobil olup olmadığını anladığımız yer
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenSize]);

  const handleInputChangeWordTurkce = (e) => {
    setClickedWord((prev) => ({
      ...prev,
      wordTurkce: e.target.value,
    }));
  };

  const handleInputChangeWordKazakca = (e) => {
    setClickedWord((prev) => ({
      ...prev,
      wordKazakca: e.target.value,
    }));
  };

  const handleInputChangeWordRusca = (e) => {
    setClickedWord((prev) => ({
      ...prev,
      wordRusca: e.target.value,
    }));
  };

  const handleInputChangeImageUrl = (e) => {
    setClickedWord((prev) => ({
      ...prev,
      imageURL: e.target.value,
    }));
  };

  const handleInputChangeCategory = (e) => {
    setClickedWord((prev) => ({
      ...prev,
      category: e.target.value,
    }));
  };

  const handleInputChangeDescription = (e) => {
    setClickedWord((prev) => ({
      ...prev,
      description: e.target.value,
    }));
  };

  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");

  const fileInputRef = useRef(null); // ref ekledik

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        setImage(compressedFile);
      } catch (error) {
        setMessage("Resim sıkıştırma hatası: " + error.message);
      }
    } else {
      setMessage("Lütfen bir resim dosyası seçin.");
    }
  };

  const handleUpload = () => {
    if (!image) {
      setMessage("Lütfen bir resim dosyası seçin.");
      return;
    }

    const generateUniqueImageName = () => {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

      return `img-${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}.jpg`;
    };

    const formData = new FormData();
    formData.append("image", image, generateUniqueImageName());

    setUploading(true);

    fetch("https://storage.leksilink.com/upload.php", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setUploading(false);
        if (data.success) {
          setMessage("Resim başarıyla yüklendi.");
          setClickedWord((prev) => ({
            ...prev,
            imageURL: "https://storage.leksilink.com/" + data.file_path,
          }));
          setImage(null);
          if (fileInputRef.current) {
            fileInputRef.current.value = null; // ref kullanarak öğeye erişim
          }
        } else {
          setMessage("Yükleme hatası: " + data.message);
        }
      })
      .catch((error) => {
        setUploading(false);
        setMessage("Ağ hatası: " + error.message);
      });
  };

  const [isWordDeleted, setIsWordDeleted] = useState(false);

  const handleSelectChange = (e) => {
    setClickedWord((prev) => ({
      ...prev,
      deleted: e.target.value === "true",
    }));
  };

  return (
    <div className="m-2 md:m-4 p-4 md:p-6  ">
      {/* Sayfa Başlığı */}
      <h3
        style={{
          fontFamily: "Poppins Regular",
          fontSize: 24,
          color: "#333333",
        }}
      >
        Tüm Kelimeler
      </h3>

      {/* Arama - Search Bar */}
      <div className="flex mt-5 items-center">
        <input
          className=" shadow-md w-[320px] h-[50px] p-2 pl-5"
          placeholder="Aramak istedğiniz terimi girin"
          style={{ borderRadius: 4, borderColor: "#707070", outline: "none" }}
          value={searchText} // Arama metnini bağlayın
          onChange={handleSearchTextChange} // Metin değiştiğinde işlevi çağırın
          onKeyPress={handleKeyPress}
        />
        <button
          className="w-[80px] h-[50px] bg-white shadow-md hover:shadow-xl"
          style={{
            borderLeftWidth: 1,
            borderColor: "#707070",
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            fontFamily: "Poppins Regular",
            fontSize: 20,
            color: "#333333",
          }}
          onClick={performSearch} // Arama düğmesine tıklandığında işlevi çağırın
        >
          Ara
        </button>

        <button
          className="bg-[#5356FF] ml-10 rounded-sm shadow-md px-4 py-2 text-white"
          onClick={() => {
            setNewWord(true);
            setShowUserModal(true);
          }}
          style={{ fontFamily: "Poppins Regular" }}
        >
          Yeni Kelime Ekle
        </button>
      </div>

      {/* TÜM DATA */}
      <div className=" mt-20">
        <div className="flex justify-between w-[96%] items-center mt-[-28px] ">
          {/* Başlıklar */}
          {!isMobile &&
            tableData.map((item) => (
              <button
                key={item.title}
                style={{
                  fontFamily: "Montserrat Regular",
                  fontSize: 18,
                  color: "#333333",
                  opacity: 0.6,
                  width: item.title === "ID" ? 50 : 200,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  marginLeft: 10,
                }}
                onClick={() => {
                  onTitleClickHandler(item.title);
                }}
              >
                {item.title}
                {item.title === sortedName &&
                  (sorted ? (
                    <AiFillCaretDown
                      style={{ marginLeft: 5, color: "#333333" }}
                    />
                  ) : (
                    <AiFillCaretDown
                      style={{
                        marginLeft: 5,
                        color: "#333333",
                        transform: "rotate(180deg)",
                      }}
                    />
                  ))}
              </button>
            ))}
        </div>

        {/* Müşteriler */}
        {!isMobile &&
          (searched
            ? searchResult.map((item, index) => (
                <TicketCard
                  item={item}
                  key={index}
                  id={index}
                  pageName="Aranacak"
                  isSelectAll={selectAll}
                  onClick={() => {
                    getUserInfo(item.email);
                    setClickedTicketID(item.id);
                    setClickedWord(item);
                    setEmail(item.email);
                    setWalletID(item.walledID);
                    setBalance(item.balance);
                    setStartDate(new Date(item.createdAt).getTime()); // burada kaldık, hata verdi tarih düzenlenecek.

                    setShowUserModal(true);
                  }}
                />
              ))
            : sorted
            ? sortedData.map((item, index) => (
                <TicketCard
                  item={item}
                  key={index}
                  id={index}
                  pageName="Aranacak"
                  isSelectAll={selectAll}
                  onClick={() => {
                    getUserInfo(item.email);
                    setClickedTicketID(item.id);
                    setClickedWord(item);
                    setEmail(item.email);
                    setWalletID(item.walledID);
                    setBalance(item.balance);
                    setStartDate(new Date(item.createdAt).getTime()); // burada kaldık, hata verdi tarih düzenlenecek.

                    setShowUserModal(true);
                  }}
                />
              ))
            : words.map((item, index) => (
                <TicketCard
                  item={item}
                  key={index}
                  id={index}
                  pageName="Aranacak"
                  isSelectAll={selectAll}
                  onClick={() => {
                    getUserInfo(item.email);
                    setClickedTicketID(item.id);
                    setClickedWord(item);
                    setEmail(item.email);
                    setWalletID(item.walledID);
                    setBalance(item.balance);
                    setStartDate(new Date(item.createdAt).getTime()); // burada kaldık, hata verdi tarih düzenlenecek.

                    setShowUserModal(true);
                  }}
                />
              )))}

        {/* MOBILE DEVICE TICKET CARD */}
        {isMobile && searched
          ? searchResult.map((item, index) => (
              <button
                key={index}
                className="w-full bg-white h-[48px] rounded-md shadow-md flex items-center justify-between px-2 mt-4"
                onClick={() => {
                  getUserInfo(item.email);
                  setClickedTicketID(item.id);
                  setClickedWord(item);
                  setEmail(item.email);
                  setWalletID(item.walledID);
                  setBalance(item.balance);
                  setStartDate(new Date(item.createdAt).getTime()); // burada kaldık, hata verdi tarih düzenlenecek.

                  setShowUserModal(true);
                }}
              >
                {/* Ticket Başlığı */}
                <div className="">
                  <p style={{ fontFamily: "Poppins Regular" }}>
                    {item.name.length > 25
                      ? `"${item.name.slice(0, 25)}..."`
                      : `"${item.name}"`}
                  </p>
                </div>

                {/* Açık - Kapalı Kutu */}
                <div
                  className={`w-[80px] h-[70%] ${
                    item.isOpen ? "bg-green-600" : " bg-gray-600"
                  } items-center flex justify-center rounded-md`}
                >
                  <p style={{ fontFamily: "Poppins Regular", color: "white" }}>
                    {item.isOpen ? "Açık" : "Kapalı"}
                  </p>
                </div>
              </button>
            ))
          : isMobile &&
            users.map((item, index) => (
              <button
                key={index}
                className="w-full bg-white h-[48px] rounded-md shadow-md flex items-center justify-between px-2 mt-4"
                onClick={() => {
                  getUserInfo(item.email);
                  setClickedTicketID(item.id);
                  setClickedWord(item);
                  setEmail(item.email);
                  setWalletID(item.walledID);
                  setBalance(item.balance);
                  setStartDate(new Date(item.createdAt).getTime()); // burada kaldık, hata verdi tarih düzenlenecek.

                  setShowUserModal(true);
                }}
              >
                {/* Ticket Başlığı */}
                <div className="">
                  <p style={{ fontFamily: "Poppins Regular" }}>
                    {item.name.length > 25
                      ? `"${item.name.slice(0, 25)}..."`
                      : `"${item.name}"`}
                  </p>
                </div>

                {/* Açık - Kapalı Kutu */}
                <div
                  className={`w-[80px] h-[70%] ${
                    item.isOpen ? "bg-green-600" : " bg-gray-600"
                  } items-center flex justify-center rounded-md`}
                >
                  <p style={{ fontFamily: "Poppins Regular", color: "white" }}>
                    {item.isOpen ? "Açık" : "Kapalı"}
                  </p>
                </div>
              </button>
            ))}

        {/* KULLANICI DETAYLARI MODAL */}
        {showUserModal && (
          <div
            className="fixed inset-0 bg-[rgba(0,0,0,0.5)] flex justify-center items-center z-20"
            onClick={() => {
              setShowUserModal(false);
              setMessage("");
              setResponse("");
              setNewWord(false);
              setClickedWord({
                wordTurkce: "",
                wordKazakca: "",
                wordRusca: "",
                audioKazakcaURL: "",
                audioTurkceURL: "",
                audioRuscaURL: "",
                imageURL: "",
                description: "",
                category: "",
                deleted: false,
                createdAt: Date.now(),
              });
            }}
          >
            {/* Modal İçi Container */}
            <div
              className="relative w-[90%] md:w-[50%] bg-[#F2F2F2] rounded-md px-3 z-10 overflow-y-auto max-h-[100vh]"
              onClick={(e) => e.stopPropagation()}
            >
              <div>
                <div className="m-2 md:m-4 p-4 md:p-6 flex flex-wrap relative justify-around">
                  {/* SOL TARAF */}
                  <div className="w-full md:w-[300px] ">
                    {/* Türkçe */}
                    <div className="mt-5 w-full md:w-[300px]">
                      <div
                        style={{
                          fontFamily: "Montserrat Regular",
                          fontSize: 18,
                          color: "#333333",
                          opacity: 0.6,
                        }}
                      >
                        Türkçe
                      </div>
                      <input
                        className="shadow-md w-[100%] md:w-[300px] h-[50px] p-2 pl-5"
                        placeholder="Türkçe"
                        style={{
                          borderRadius: 4,
                          borderColor: "#707070",
                          outline: "none",
                          marginTop: 5,
                        }}
                        value={clickedWord.wordTurkce} // Arama metnini bağlayın
                        onChange={handleInputChangeWordTurkce}
                      />
                    </div>

                    {!isMobile && (
                      <>
                        {/* Email Input */}
                        <div className="mt-5">
                          <div
                            style={{
                              fontFamily: "Montserrat Regular",
                              fontSize: 18,
                              color: "#333333",
                              opacity: 0.6,
                              width: 300,
                            }}
                          >
                            Kazakça
                          </div>
                          <input
                            className="shadow-md w-[96%] md:w-[300px] h-[50px] p-2 pl-5"
                            placeholder="Kazakça "
                            style={{
                              borderRadius: 4,
                              borderColor: "#707070",
                              outline: "none",
                              marginTop: 5,
                            }}
                            value={clickedWord.wordKazakca} // Arama metnini bağlayın
                            onChange={handleInputChangeWordKazakca}
                          />
                        </div>

                        {/* Rusça Input */}
                        <div className="mt-5">
                          <div
                            style={{
                              fontFamily: "Montserrat Regular",
                              fontSize: 18,
                              color: "#333333",
                              opacity: 0.6,
                              width: 300,
                            }}
                          >
                            Rusça
                          </div>
                          <input
                            className="shadow-md w-[300px] h-[50px] p-2 pl-5"
                            placeholder="Rusça"
                            style={{
                              borderRadius: 4,
                              borderColor: "#707070",
                              outline: "none",
                              marginTop: 5,
                            }}
                            value={clickedWord.wordRusca} // Arama metnini bağlayın
                            onChange={handleInputChangeWordRusca}
                          />
                        </div>

                        {/* Resim Input */}
                        <div className="mt-5">
                          <div
                            style={{
                              fontFamily: "Montserrat Regular",
                              fontSize: 18,
                              color: "#333333",
                              opacity: 0.6,
                              width: 300,
                            }}
                          >
                            Resim URL
                          </div>
                          <input
                            className="shadow-md w-[300px] h-[50px] p-2 pl-5"
                            placeholder="Resim URL"
                            style={{
                              borderRadius: 4,
                              borderColor: "#707070",
                              outline: "none",
                              marginTop: 5,
                            }}
                            value={clickedWord.imageURL} // Arama metnini bağlayın
                            onChange={handleInputChangeImageUrl}
                          />
                        </div>

                        <div className="mt-5">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            disabled={uploading}
                          />
                          <button
                            className="bg-[#5356FF] mt-5 rounded-sm shadow-md px-4 py-2 text-white"
                            onClick={handleUpload}
                            disabled={uploading || !image}
                            style={{ fontFamily: "Poppins Regular" }}
                          >
                            Yeni Resmi Yükle
                          </button>
                          {message && <p>{message}</p>}
                        </div>
                      </>
                    )}
                  </div>

                  {/* SAĞ TARAF */}
                  <div
                    className="w-full md:w-auto  justify-between overflow-y-auto  "
                    style={{ borderRadius: 4 }}
                  >
                    {/* Açıklama Input */}
                    {/* <div className="mt-5">
                      <div
                        style={{
                          fontFamily: "Montserrat Regular",
                          fontSize: 18,
                          color: "#333333",
                          opacity: 0.6,
                          width: 300,
                        }}
                      >
                        Açıklama
                      </div>
                      <textarea
                        className="shadow-md w-[400px] h-[120px] p-2 pl-5"
                        placeholder="Açıklama"
                        style={{
                          borderRadius: 4,
                          borderColor: "#707070",
                          outline: "none",
                          marginTop: 5,
                        }}
                        value={clickedWord.description} // Arama metnini bağlayın
                        onChange={handleInputChangeDescription}
                      />
                    </div> */}

                    {/* Kategori Input */}
                    <div className="mt-5">
                      <div
                        style={{
                          fontFamily: "Montserrat Regular",
                          fontSize: 18,
                          color: "#333333",
                          opacity: 0.6,
                          width: 300,
                        }}
                      >
                        Kategori
                      </div>
                      <input
                        className="shadow-md w-[300px] h-[40px] p-2 pl-5"
                        placeholder="Kategori"
                        style={{
                          borderRadius: 4,
                          borderColor: "#707070",
                          outline: "none",
                          marginTop: 5,
                        }}
                        value={clickedWord.category} // Arama metnini bağlayın
                        onChange={handleInputChangeCategory}
                      />
                    </div>

                    <div className="flex justify-between ">
                      {/* Silme Input */}
                      <div className="mt-5  ">
                        <div
                          style={{
                            fontFamily: "Montserrat Regular",
                            fontSize: 18,
                            color: "#333333",
                            opacity: 0.6,
                            width: 120,
                          }}
                        >
                          Sil
                        </div>

                        <select
                          className="shadow-md w-[100px] h-[40px] p-2 pl-5"
                          id="isWordDeleted"
                          value={clickedWord.deleted}
                          onChange={handleSelectChange}
                        >
                          <option value="true">Evet</option>
                          <option value="false">Hayır</option>
                        </select>
                      </div>

                      {/* Tarih Input */}
                      <div className="mt-5 ">
                        <div
                          style={{
                            fontFamily: "Montserrat Regular",
                            fontSize: 18,
                            color: "#333333",
                            opacity: 0.6,
                            width: 180,
                          }}
                        >
                          Ekleme Tarihi
                        </div>

                        <div className="pl-5 bg-white mt-[5px]">
                          <DatePicker
                            className="h-[40px] w-[150px]"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            timeInputLabel="Saat:"
                            dateFormat="dd/MM/yyyy HH:mm"
                            showTimeInput
                            locale="tr"
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="flex flex-col mt-5">
                      <label>Upload Audio:</label>
                      <input
                        type="file"
                        accept="audio/*"
                        onChange={handleAudioChange}
                      />
                      <button onClick={handleAudioUpload}>Upload Audio</button>
                    </div> */}

                    {/* Kazakça Ses Yükleme Bölümü */}
                    <div className="mt-2 flex flex-col">
                      <h4
                        className="text-[#5356FF] mb-2"
                        style={{ fontFamily: "Poppins Medium" }}
                      >
                        Kazakça Ses Yükle
                      </h4>
                      <input
                        className="w-[280px]"
                        type="file"
                        accept="audio/*"
                        onChange={handleAudioChange}
                      />
                      <button
                        className="bg-[#5356FF] mt-2 w-[280px] rounded-sm shadow-md px-4 py-2 text-white"
                        onClick={handleAudioUpload}
                        disabled={uploading || !audio}
                        style={{ fontFamily: "Poppins Regular" }}
                      >
                        Kazakça Sesi Yükle
                      </button>
                      {response && <p>{response}</p>}
                      {/* {audioURL && <p>{audioURL}</p>} */}
                    </div>

                    {/* Türkçe Ses Yükleme Bölümü */}
                    <div className="mt-2 flex flex-col">
                      <h4
                        className="text-[#5356FF] mb-2"
                        style={{ fontFamily: "Poppins Medium" }}
                      >
                        Türkçe Ses Yükle
                      </h4>
                      <input
                        className="w-[280px]"
                        type="file"
                        accept="audio/*"
                        onChange={handleAudioChangeTurkce}
                      />
                      <button
                        className="bg-[#5356FF] mt-5 w-[280px] rounded-sm shadow-md px-4 py-2 text-white"
                        onClick={handleAudioUploadTurkce}
                        disabled={uploading || !audioTurkce}
                        style={{ fontFamily: "Poppins Regular" }}
                      >
                        Türkçe Sesi Yükle
                      </button>
                      {responseTurkce && <p>{responseTurkce}</p>}
                    </div>

                    {/* Rusça Ses Yükleme Bölümü */}
                    <div className="mt-5 flex flex-col">
                      <h4
                        className="text-[#5356FF] mb-2"
                        style={{ fontFamily: "Poppins Medium" }}
                      >
                        Rusça Ses Yükle
                      </h4>
                      <input
                        className="w-[280px]"
                        type="file"
                        accept="audio/*"
                        onChange={handleAudioChangeRusca}
                      />
                      <button
                        className="bg-[#5356FF] mt-5 w-[280px] rounded-sm shadow-md px-4 py-2 text-white"
                        onClick={handleAudioUploadRusca}
                        disabled={uploading || !audioRusca}
                        style={{ fontFamily: "Poppins Regular" }}
                      >
                        Rusça Sesi Yükle
                      </button>
                      {responseRusca && <p>{responseRusca}</p>}
                    </div>
                  </div>
                  {/* Modeli Kapat Butonu */}
                  <button
                    className="absolute top-2 right-2"
                    onClick={() => {
                      setShowUserModal(false);
                      setMessage("");
                      setResponse("");
                      setNewWord(false);
                      setClickedWord({
                        wordTurkce: "",
                        wordKazakca: "",
                        wordRusca: "",
                        audioKazakcaURL: "",
                        audioTurkceURL: "",
                        audioRuscaURL: "",
                        imageURL: "",
                        description: "",
                        category: "",
                        deleted: false,
                        createdAt: Date.now(),
                      });
                    }}
                  >
                    <MdOutlineCancel size={36} color="red" />
                  </button>
                </div>

                {/* Kaydet Butonu - En Alt */}
                <button
                  className="p-4 px-10 mb-7 ml-[85px] bg-gray-600 shadow-md hover:shadow-xl"
                  style={{
                    borderWidth: 1,
                    borderColor: "#707070",
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                    fontFamily: "Poppins Medium",
                    fontSize: 20,
                    color: "white",
                  }}
                  onClick={() => {
                    // console.log(
                    //   "Gönderilecek clickedWord nesnesi:",
                    //   clickedWord
                    // );

                    Swal.fire({
                      title: "Uyarı",
                      text: "Kaydetmek istediğinize emin misiniz?",
                      icon: "warning",
                      showCancelButton: true,
                      cancelButtonText: "İptal",
                      confirmButtonText: "Kaydet",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        if (newWord) {
                          //console.log("New Word");
                          addNewWord(myEmail, jwtToken, clickedWord);
                          setNewWord(false);
                        } else {
                          //console.log("Kelime güncelleniyor:", clickedWord);
                          updateWord(myEmail, jwtToken, clickedWord);
                        }
                      }
                    });
                  }}
                >
                  Kaydet
                </button>
                <ToastContainer />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;
