import axios from "axios";

export let baseURL = "https://leksi.fellasyazilim.com/api/v1";
//export const baseURL = "http://localhost:3000/api/v1";

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosInstance;
